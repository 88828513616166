@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

.accordion-container {
  min-height: 100vh;
  padding: 130px 5vw 90px 5vw;
  width: 100vw;
  box-sizing: border-box;
}

.accordion-list {
  max-width: 850px;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 20px;
}

/* Style the accordion section */
.accordion-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fff;
  color: #606060;
  cursor: pointer;
  padding: 30px 20px;
  border: none;
  outline: none;
  box-shadow: var(--shadow);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.disabled .accordion {
  opacity: 0.5;
  cursor: unset;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active {
  border-radius: 10px 10px 0 0;
}

/* Style the accordion content title */
.accordion-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

/* Style the accordion chevron icon */
.accordion-icon, .bi-plus {
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  background-color: white;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

/* Style the accordion content text */
.accordion-text {
  font-weight: 400;
  padding: 25px;
  color: #757575;
  line-height: 29px;
  box-sizing: border-box;
}

.bi-plus {
  color: #171717 !important;
  font-size: 2rem;
}

.label-container {
  border-radius: 5px;
  display: flex;
  margin: 0;
  align-items: center;
  width: auto;
}

@media screen and (max-width: 550px) {
  .accordion, .accordion-content {
    max-width: 90vw;
  }
}

