.custom-table {
  max-width: 70vw;
  width: 100%;
  background: var(--white);
}

.pagination-container {
  max-width: 70vw;
}

.table-container {
  margin: 60px 0;
}

.table-container {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1300px) {
  .page-with-navbar {
    margin-left: 270px;
  }

  .page-with-navbar .table-container {
    max-width: 70vw;
    margin-right: 5vw;
  }

  .page-with-navbar .pagination-container {
    max-width: 300px;
  }

}

@media screen and (max-width: 1000px) {
  .table-container, .pagination-container {
    max-width: 90vw;
  }
}

@media screen and (max-width: 725px) {
  .page-with-navbar {
    margin-left: 0;
  }

  .page-with-navbar .table-container {
    max-width: 90vw;
    margin-right: 0;
  }

  .page-with-navbar .pagination-container {
    max-width: 300px;
  }
}

.custom-table {
  color: #606060;
  border: 3px solid var(--white);
  white-space: nowrap;
}

.custom-table {
  color: #606060;
  border: 3px solid var(--white);
  white-space: nowrap;
}

td {
  padding: 20px 1.2vw;
  text-align: center;
}

.custom-tbody tr:nth-child(even) {
  background-color: var(--secondary-background);
}

.pagination-bar {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box !important;
  justify-content: space-between;
  max-width: 300px;
}

.pagination-bar button {
  padding: 8px 20px;
  color: #606060;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.pagination-bar button:nth-child(1) {
  border-radius: 10px 0 0 10px;
}

.pagination-bar button:nth-child(5) {
  border-radius: 0 10px 10px 0;
}

.currentPage {
  background-color: #3d3d3d !important;
  color: #fff !important;
  min-width: 10px !important;
  width: 10px !important;
  border: none !important;
}

.pagination-numbers {
  display: flex;
  flex-wrap: wrap;
  color: #606060;
  width: auto;
  align-items: center;
  justify-content: end;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}
