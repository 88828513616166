.btn-primary {
  background-color: #ffa30fff !important;
  border-color: #ffa30fff !important;
  color: #fff !important;
}

.btn-primary:disabled {
  background-color: rgb(255, 186, 74) !important;
  border-color: rgb(255, 186, 74) !important;
  color: #fff !important;
}

.btn-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color: rgb(209, 129, 0) !important;
  color: #fff !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: rgb(221, 107, 0) !important;
}

.btn-outline-primary {
  color: rgb(221, 107, 0) !important;
  border-color: rgb(209, 129, 0) !important;
}

.btn-outline-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color: rgb(209, 129, 0) !important;
  color: #fff !important;
}

.btn-flash:hover:not(:disabled) {
  background-color: rgb(242, 115, 157) !important;
  border-color: rgb(242, 115, 157) !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
}

.btn-flash:disabled {
  background-color: rgb(242, 115, 157) !important;
  border-color: rgb(242, 115, 157) !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
}

.btn-flash {
  background-color: #171717 !important;
  display: block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 50px;
  height: 50px;
  font-family: 'Poppins', sans-serif;
  border: none;
  cursor: pointer;
}

/* Botões */

.outline-button {
  height: 50px;
  width: 250px;
  padding: 0 30px;
  border-radius: 50px;
  border: 1px solid var(--font-secondary-color);
  background-color: transparent;
}

.pink {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.short-button {
  width: 194px;
  height: 50px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: var(--white);
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.clear-filters-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 5px 20px;
  height: 45px;
  min-width: 194px;
  box-sizing: border-box;
  border-radius: 50px;
  margin: 20px 0;
  border: none;
  display: flex;
  justify-content: space-between;
  outline: none;
  align-items: center;
  gap: 5px;
}

.clear-filters-button .bi-x{
  font-size: 1.7em;
}

.button-fill {
  background-color: var(--primary-color);
  color: var(--white) !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.invisible-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.button-100 {
  width: 100%;
  min-width: 150px;
}

.button-login {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
