@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
.multipart{
  display: flex;
  grid-gap: 5vw;
  gap: 5vw;
  width: 100%;
  min-height: 600px;
  border-radius: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: start;
}

.form-index {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.form-index-labels p {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 30px 0;
  text-align: end;
}

.form-index-buttons {
  display: grid;
  grid-template-columns: auto; /* Uma coluna para os botões */
}

.form-index-buttons button {
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: solid 1px var(--font-secondary-color);
  color:  var(--font-secondary-color);
  transition: all 0.2s;
}


.active-btn{
  background-color: black !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s;
}

.line{
  height: 30px;
  background-color: var(--font-secondary-color);
  width: 1px;
  margin: 0 auto;
}

.outline-btn{
  background-color: transparent;
  color: var(--primary-background);
  border-radius: 30px;
  width: 200px;
  height: 50px;
  border: solid 1px var(--primary-background);
}


.form-step{
  width: 100%;
  height: 100%;
  display: grid;
  align-items: stretch;
  grid-gap: 30px;
  gap: 30px;
}

.step-content{
  width: 100%;
}

.step-controls{
  display: flex;
  justify-content: start;
  align-items: end;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.app-visual-input{
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  align-items: start;
  flex-wrap: wrap;
}

.device-mockup{
  display: flex;
}

.device-screen{
  background-color: #ffffff;
  width: 280px;
  height: 600px;
  border-radius: 20px;
  border: solid 6px #121515;
  border-bottom: solid 10px #121515;
  display: block;
}

.silver-border{
  border-radius: 20px;
  background-color: #121515;
  border: solid 6px #3A4245;
}

.button-volume{
  background-color: #121515;
  width: 4px;
  height: 70px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.button-power{
  background-color: #121515;
  width: 4px;
  height: 37px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.device-buttons{
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 110px;
}

.device-camera{
  width: 16px;
  height: 16px;
  background-color: #262C2D;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0;
}

.camera-glass{
  width: 8px;
  height: 8px;
  background-color: #121515;
  border-radius: 100%;
}

.camera-brilliance{
  height: 2px;
  width: 2px;
  background-color: #656A6B;
  border-radius: 20px;
  margin: 2px auto 0 4px;
}

.device-content{
  height: calc(100% - 20px);
  border-radius: 40px;
}

.icon-preview-container{
  width: 300px;
  height: 170px;
  border-radius: 30px;
  padding: 20px 30px;
  box-sizing: border-box;
  background-image: url(/static/media/background.a18ce0d1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-items: flex-end;
  grid-gap: 20px;
  gap: 20px;
}

.icon-pattern{
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-pattern-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height: 100%;
  object-fit: cover;
}

.instagram-background{
  background: radial-gradient(circle at 33% 100%,
  #f5804e 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  );
  display: flex;
  justify-content: center;
  padding-top: 5px;
  box-sizing: border-box;
}

.bi-instagram, .bi-twitch{
  font-size: 30px;
  color: #fff;
}


.twitch-background{
  background-color: #9046FF;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  box-sizing: border-box;
}

.icons-preview{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icons-preview *{
  transition: all 1s;
}

.icon-placeholder{
  background-color: #FFFFFF;
  position: relative;
}

.navigation-controls{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navigation-controls .bi{
  font-size: 20px;
  color: #fff;
}

.icon-placeholder:hover{
  height: 60px;
  width: 60px;
  cursor: pointer;
  transition: all 1s;
}

.lock-type-container{
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: space-evenly;
  grid-gap: 40px;
  gap: 40px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.lock-type-container *{
  margin: 0 auto;
}

.partial-lock-pop-up{
  box-shadow: var(--shadow);
  background-color: white;
  text-align: center;
  padding: 10px 10px 25px;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-content: center;
  align-items: center;
  width: 100%;
  border-radius: 20px;
}

.ads-pop-up{
  box-shadow: var(--shadow);
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  display: grid;
  padding: 10px;
  justify-content: center;
  align-content: space-evenly;
}

.ads-container{
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
  align-content: center;
  text-align: center;
}

.ads-container img{
  margin: 0 auto;
}

.ads-placeholder{
  height: 230px;
  background-color: var(--silver);
  border-radius: 20px;
}

.timer-example{
  height: 40px;
  width: 40px;
  border: solid 2px var(--silver);
  display: flex;
  justify-content: center;
  color: var(--font-secondary-color);
  border-radius: 30px;
  font-size: 30px;
}

.align-to-right{
  display: flex;
  justify-content: right;
}


.launcher{
  height: calc(100% - 10px);
  width: 100%;
  background-color: silver;
  border-radius: 10px;
  margin-top: 10px;
  background-image: url(/static/media/background.692b2999.jpg);
  background-size: cover;
  background-position: center;
  color: #FFFFFF;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  align-items: flex-start;
}

body {
  padding: 0;
  background-color: #FFFFFF !important;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-background);
}

body::-webkit-scrollbar-thumb {
  background: var(--font-secondary-color) !important;
}

.app-main {
  padding: 100px 10vw;
  min-height: 200vh;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 20px 5vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}

.app-header h1 {
  font-size: 1.3em;
}

.app-display {
  display: flex;
  grid-gap: 5vw;
  gap: 5vw;
  align-items: center;
  text-align: center;
}

.app-name {
  font-size: 1.8em;
  font-weight: normal;
  text-align: left;
}

.app-category {
  margin-top: 20px;
  font-size: 1.3em;
  font-weight: normal;
  color: #616161;
}

.app-icon {
  max-width: 200px;
  max-height: 200px;
}

.app-icon img {
  width: 100%;
  border-radius: 20px;
}

.app-download {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  justify-content: space-between;
}

.download-button-mobile-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 915px) {
  .download-button-mobile-container {
    width: 100%;
    justify-content: center;
    margin-top: 40px;
  }

  .qr-code-container, .app-qr-code {
    width: 100%;
  }

  .qr-code-container{
    margin-top: 40px;
  }

  .app-display{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 60px;
    gap: 60px;
    justify-content: center;
  }
}

.qr-code-container {
  position: relative;
  display: inline-block;
}

.download-button {
  padding: 10px;
  background-color: #000000;
  border-radius: 90px;
  width: 200px;
  height: 35px;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  font-size: 1.2em;
}

.download-button .bi{
  -webkit-text-stroke: 0.6px;
}

.download-link {
  display: none;
  position: absolute;
  bottom: 35%;
  left: 10%;
}

.app-qr-code {
  transition: 0.7s;
}

.qr-code-container:hover > .app-qr-code {
  filter: blur(8px);
  opacity: 0.4;
  transition: 0.7s;
}

.qr-code-container:hover > .download-link {
  display: flex;
  transition: 0.3s;
}

.app-silver-box {
  display: grid;
  padding: 20px;
  background-color: #F3F3F3;
  border-radius: 10px;
  grid-gap: 20px;
  gap: 20px;
}

.app-h4 {
  font-weight: 500;
  font-size: 1.3em;
}

.app-information {
  display: flex;
  flex-wrap: wrap;
  padding: calc(10vw + 30px) 0;
}

.card {
  flex: 1 1;
  min-width: 300px; /* Adjust as needed */
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .card {
    flex: 0 0 100%;
  }

  .app-main {
    padding: 100px 5vw;
  }

  .app-name {
    text-align: center;
  }
}

.app-information div {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}

.app-instalation-manual {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.chevron {
  min-height: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.app-instalation-manual .app-h4 {
  text-align: center;
  margin-bottom: 60px;
}


.store-tab-menu{
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}


.tab-active{
  background-color: #F3F3F3;
}

.store-tab-menu li{
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.installation-container{
  padding: 80px 0;
}



/* LIGHTBOX */

.wrapper {
  max-width: 1350px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 80px;
}

.wrapper-images{
  text-align: center;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  color: silver;
}

.wrapper-images img{
  text-align: center;
  display: grid;
  max-width: 400px;
  height: 500px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 5px;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 999;
}

.overlay-arrows_right {
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 999;
}

.dismiss{
  z-index: 999;
  font-size: 2em;
}

/* LIGHTBOX */


.inner-carrousel {
  display: flex !important;
  box-sizing: border-box;
  grid-gap: 20px;
  gap: 20px;
}

.carrousel {
  cursor: grab;
  overflow-x: hidden;
  box-sizing: border-box;
}



.imei-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
}


.imei-header {
  margin-bottom: 20px;
  text-align: center;
}


.imei-logo {
  max-width: 250px;
  height: auto;
}


.imei-main {
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
}


.imei-form {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}


.imei-inputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
  font-weight: bold
}


.imei-input {
  width: 100%;
  padding: 10px;
  border: 1px solid white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 5px;
  box-sizing: border-box;
}


.imei-link {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  align-self: center;
}

.button-86 {
  all: unset;
  width: 90%;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 20px;
}



.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #FFFFFF;
}

.loaderr {
  color: black;
  font-size: 45px;
  text-indent: -1000em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  margin-bottom: 20px;
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 4px;
  display: block;
}


@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em,
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
    -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
    -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
    -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
    -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}


.loading-text {
  font-size: 25px;
  color: black;
}

.dot {
  animation: blink 1.5s infinite;
}

.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.2s; }
.dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}







.container-activate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}


.main-activate {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}


.logo-activate {
  max-width: 300px;
  height: auto;
  margin-bottom: 30px;
}


.button-86-activate {
  width: 300px;
  height: 50px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 60px;
}

.button-86-activate:hover {
  background-color: #555;
}



.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #FFFFFF;
}


@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em,
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
    -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
    -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
    -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
    -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}


.loading-text {
  font-size: 25px;
  color: black;
}

.dot {
  animation: blink 1.5s infinite;
}

.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.2s; }
.dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

:root {
  --white: #ffffff;
  --primary-background: #212121;
  --secondary-background: #f5f5f5;
  --silver: #e0e0e0;
  --silver-hover: #eee;
  --primary-color: #171717;
  --font: 'Poppins', sans-serif;
  --font-primary-color: #212121;
  --font-secondary-color: #757575;
  --font-tertiary-color: #bdbdbd;
  --shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

a{
    text-decoration: none;
}

.notification-success:before {
  content: "" !important;
}

.notification-info:before {
  content: "" !important;
}

.notification-warning:before {
  content: "" !important;
}

.notification-error:before {
  content: "" !important;
}

.notification {
  padding: 15px 0 15px 15px !important;
}

nav{
  position:fixed;
  top:0;
  left:0;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  min-width: 250px;
  width: 250px;
  min-height: 100vh;
  background-color: #FFF;
  padding:2rem 1rem 1rem 1rem;
  transition: transform 1s;
  color: #171717 !important;
  box-sizing: border-box;
  box-shadow: var(--shadow);
  z-index: 9;
}

nav * {
  color: #606060 !important;
  font-size: 1.1rem;
}

nav .bi {
  color: #171717 !important;
}

.navbar{
  transform: translateX(-100%);
}

.nav-btn{
  position: absolute;
  transform: translateX(38px);
  top: 20px;
  right: 0;
  width:40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #171717 !important;
  box-shadow: rgba(149, 157, 165, 0.1) 8.4px 8.4px 14px;
}

.logo{
  display: block;
  background:transparent;
}

.logo img{
  display: block;
  width: 90%;
  height: 90%;
  margin: auto;
}

.partner-name{
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.nav-link{
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 10px 1rem;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.nav-link span{
  margin-left: 10px;
}

.language-option-visible{
  display: grid;
  padding: 30px;
  animation: fade 2s;
  grid-gap: 15px;
  gap: 15px;
  background: #F8F8F8;
  border-radius: 5px;
}

.language-option-visible span{
  cursor: pointer;
}

.language-option{
  display: none;
  margin-left: 20px;
}

.language{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.language img{
  width: 20px;
}

@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.nav-top{
  margin-top: 15vh;
  grid-gap: 5vh;
  gap: 5vh;
}

.mobile-nav{
  background-color: #FFF;
  width: 100vw;
  height:38px;
  display: none;
  box-shadow: var(--shadow);
  z-index: 10;
  position: fixed;
}

.mobile-nav-btn{
  box-sizing: border-box;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 10px;
}

.mobile-nav-btn .bi {
  font-size: 20px;
  color: #171717;
}

@media only screen and (max-width: 768px){
  nav{
    padding: 10px;
  }
}

@media only screen and (max-width: 450px){
  .mobile-nav{
    display: flex;
    align-items: center;
  }
  nav{
    width: 70%;
    top: 38px;
    min-height: calc(100vh - 38px);
  }

  .nav-btn{
    display: none;
  }

  .blur{
    filter: blur(20px);
  }
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F8F8F8;
  overflow-x: hidden;
}

body, a {
  text-decoration: none !important;
  font-family: var(--font);
  color: var(--font-primary-color);
  font-size: 1em;
}

body, input {
  font-family: var(--font);
}

body, textarea {
  font-family: var(--font);
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-background);
}

body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.content {
  padding: 50px 0;
}

.App,
.content {
  overflow-x: hidden !important;
}

.demo-container {
  margin: 0 5%;
  width: 100%;
}

.page {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  transition: margin-left 1s;
}

.page-with-navbar {
  margin-left: 270px;
}

.portal-custom-select {
  grid-gap: 20px;
  gap: 20px;
  display: flex;
}

.portal-custom-select select {
  max-width: 150px;
}

.label-container {
  border-radius: 5px;
  display: flex;
  margin: 0;
  align-items: center;
  width: auto;
}

.label-container p {
  padding: 0;
  margin: 0;
}

.custom-select {
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: var(--shadow);
  -webkit-appearance: none;
  width: 60px;
  text-align: center;
  cursor: pointer;
}

.page-title {
  margin: 60px 0;
  color: #606060;
  font-size: 1.3rem;
}

.input-text-b {
  height: 50px;
  box-shadow: var(--shadow);
  -webkit-appearance: none;
  background-color: #FFF;
  padding: 0 20px;
  border-radius: 10px;
  white-space: nowrap;
}

.search-input {
  display: flex;
  align-items: center;
}

@media (max-width: 850px) {
  .search-input {
    display: grid;
    grid-gap: 30px;
    gap: 30px;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 30px;
  background-image: url(/static/media/bg.a6abc043.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  top: 0;
}

.glass-effect{
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

@media (max-width: 850px) {
  .search-input {
    display: grid;
    grid-gap: 30px;
    gap: 30px;
  }
}

.background-login {
  background-color: #FFF !important;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
}

.background-image-profile {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

.background-card {
  border-radius: 10px;
  height: auto;
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}

.text-center {
  text-align: center;
}

.background-card-password {
  border-radius: 10px;
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}

.silver {
  color: #606060;
}

.bi-accent {
  color: #171717;
  font-size: 1.1rem;
}

.filter-container {
  max-width: 70vw;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
}

.invisible-input {
  border: none;
  height: 94%;
  outline: none;
  background: transparent;
}

.center-all {
  display: grid;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}

@media (max-width: 750px) {
  .label-same-size {
    width: 200px;
  }
}

@media (max-width: 540px) {
  .portal-custom-select {
    display: grid;
  }
}

button {
  font-size: 1rem;
  cursor: pointer;
}

.bottom-container {
  padding: 100px 0;
}

.custom-form {
  max-width: 400px;
  background: #FFF;
  padding: 0 50px;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  color: #606060;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  -webkit-appearance: none;
}

@media only screen and (max-width: 650px) {
  .custom-form {
    max-width: 80vw;
  }

  .text-and-button {
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }

  .invisible-input, .input-text {
    max-width: 100% !important;
  }
}

.accent {
  color: #171717;
}

.center-button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.input-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.text-and-button {
  display: flex;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.loader {
  border: 3px solid #c0c0c0;
  border-top: 3px solid #FFF;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}

.pink-loader {
  border: 3px solid #171717;
  border-top: 3px solid #282828;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.unavailable-container {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 85vh;
  font-size: 2rem;
}

.unavailable {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  text-align: center;
}

.unavailable .bi {
  font-size: 10rem;
  color: #171717;
}

h1{
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 2.5rem;
}

.language-login{
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 30px;
  z-index: 2;
}

.black *{
  color: var(--font-primary-color) !important;
}

.qr-code-container, .qr-code-image-container{
  display: grid;
  grid-gap: 30px !important;
  gap: 30px !important;
}

.qr-code-image{
  max-width: 300px;
  width: 100%;
  box-shadow: var(--shadow);
  border-radius: 20px;
}

.custom-table {
  max-width: 70vw;
  width: 100%;
  background: var(--white);
}

.pagination-container {
  max-width: 70vw;
}

.table-container {
  margin: 60px 0;
}

.table-container {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1300px) {
  .page-with-navbar {
    margin-left: 270px;
  }

  .page-with-navbar .table-container {
    max-width: 70vw;
    margin-right: 5vw;
  }

  .page-with-navbar .pagination-container {
    max-width: 300px;
  }

}

@media screen and (max-width: 1000px) {
  .table-container, .pagination-container {
    max-width: 90vw;
  }
}

@media screen and (max-width: 725px) {
  .page-with-navbar {
    margin-left: 0;
  }

  .page-with-navbar .table-container {
    max-width: 90vw;
    margin-right: 0;
  }

  .page-with-navbar .pagination-container {
    max-width: 300px;
  }
}

.custom-table {
  color: #606060;
  border: 3px solid var(--white);
  white-space: nowrap;
}

.custom-table {
  color: #606060;
  border: 3px solid var(--white);
  white-space: nowrap;
}

td {
  padding: 20px 1.2vw;
  text-align: center;
}

.custom-tbody tr:nth-child(even) {
  background-color: var(--secondary-background);
}

.pagination-bar {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box !important;
  justify-content: space-between;
  max-width: 300px;
}

.pagination-bar button {
  padding: 8px 20px;
  color: #606060;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.pagination-bar button:nth-child(1) {
  border-radius: 10px 0 0 10px;
}

.pagination-bar button:nth-child(5) {
  border-radius: 0 10px 10px 0;
}

.currentPage {
  background-color: #3d3d3d !important;
  color: #fff !important;
  min-width: 10px !important;
  width: 10px !important;
  border: none !important;
}

.pagination-numbers {
  display: flex;
  flex-wrap: wrap;
  color: #606060;
  width: auto;
  align-items: center;
  justify-content: end;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.small-info-container{
    display: flex;
    width: 381px;
    height: 143px;
    background: #FFFFFF;
    box-shadow: var(--shadow);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    text-align: right;
    margin: 30px 0;
}

.small-info-container .silver{
    padding: 0;
    margin: 0;
}

.strong-pink-label{
    font-weight: 600;
    color: var(--primary-color);
    font-size: 50px;
}

.pink-phone{
    height: 70px;
    width: 70px;
    font-size: 4em;
    color: var(--primary-color);
    align-items: center;
}

.notification-success:before {
  content: "" !important;
}

.notification-info:before {
  content: "" !important;
}

.notification-warning:before {
  content: "" !important;
}

.notification-error:before {
  content: "" !important;
}

.notification {
  padding: 15px 0 15px 15px !important;
}

.accordion-container {
  min-height: 100vh;
  padding: 130px 5vw 90px 5vw;
  width: 100vw;
  box-sizing: border-box;
}

.accordion-list {
  max-width: 850px;
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}

/* Style the accordion section */
.accordion-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fff;
  color: #606060;
  cursor: pointer;
  padding: 30px 20px;
  border: none;
  outline: none;
  box-shadow: var(--shadow);
  border-radius: 10px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.disabled .accordion {
  opacity: 0.5;
  cursor: unset;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active {
  border-radius: 10px 10px 0 0;
}

/* Style the accordion content title */
.accordion-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

/* Style the accordion chevron icon */
.accordion-icon, .bi-plus {
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  background-color: white;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

/* Style the accordion content text */
.accordion-text {
  font-weight: 400;
  padding: 25px;
  color: #757575;
  line-height: 29px;
  box-sizing: border-box;
}

.bi-plus {
  color: #171717 !important;
  font-size: 2rem;
}

.label-container {
  border-radius: 5px;
  display: flex;
  margin: 0;
  align-items: center;
  width: auto;
}

@media screen and (max-width: 550px) {
  .accordion, .accordion-content {
    max-width: 90vw;
  }
}


.btn-primary {
  background-color: #ffa30fff !important;
  border-color: #ffa30fff !important;
  color: #fff !important;
}

.btn-primary:disabled {
  background-color: rgb(255, 186, 74) !important;
  border-color: rgb(255, 186, 74) !important;
  color: #fff !important;
}

.btn-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color: rgb(209, 129, 0) !important;
  color: #fff !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: rgb(221, 107, 0) !important;
}

.btn-outline-primary {
  color: rgb(221, 107, 0) !important;
  border-color: rgb(209, 129, 0) !important;
}

.btn-outline-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color: rgb(209, 129, 0) !important;
  color: #fff !important;
}

.btn-flash:hover:not(:disabled) {
  background-color: rgb(242, 115, 157) !important;
  border-color: rgb(242, 115, 157) !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
}

.btn-flash:disabled {
  background-color: rgb(242, 115, 157) !important;
  border-color: rgb(242, 115, 157) !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
}

.btn-flash {
  background-color: #171717 !important;
  display: block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 50px;
  height: 50px;
  font-family: 'Poppins', sans-serif;
  border: none;
  cursor: pointer;
}

/* Botões */

.outline-button {
  height: 50px;
  width: 250px;
  padding: 0 30px;
  border-radius: 50px;
  border: 1px solid var(--font-secondary-color);
  background-color: transparent;
}

.pink {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.short-button {
  width: 194px;
  height: 50px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: var(--white);
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.clear-filters-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 5px 20px;
  height: 45px;
  min-width: 194px;
  box-sizing: border-box;
  border-radius: 50px;
  margin: 20px 0;
  border: none;
  display: flex;
  justify-content: space-between;
  outline: none;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.clear-filters-button .bi-x{
  font-size: 1.7em;
}

.button-fill {
  background-color: var(--primary-color);
  color: var(--white) !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.invisible-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.button-100 {
  width: 100%;
  min-width: 150px;
}

.button-login {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.dashboard{
  width: 100%;
  max-width: 75vw;
  margin: 0 5%;
}

.charts{
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.chart{
  max-width: 100%;
  box-sizing: border-box;
}

#reactgooglegraph-1 div, #reactgooglegraph-2 div{
  background: #FFF !important;
}

@media screen and (max-width: 865px) {
  .dashboard{
    max-width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  .dashboard{
    max-width: 90vw;
  }
}

.group-components{
  width: 100%;
  background: #FFF;
  box-shadow: rgba(110, 110, 110, 0.2) 0px 8px 24px;
  border-radius: 10px !important;
  padding: 5px 20px 20px;
  box-sizing: border-box;
}

.Dropdown-menu{
  max-width: 200px;
}

.alert{
  background: var(--silver);
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px var(--font-tertiary-color);
}

.display-flex{
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.dimension-example{
    width:320px;
    height: 96px;
    border: 1.5px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
}

.dimension-logo-example{
    max-width:100%;
    height: auto;
    border: 3px solid white;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top:10px;
    display: flex;
    justify-content: center;
    object-fit: contain;
}

@media screen and (min-width: 550px) {
  .dimension-logo-example {
      width:450px;
      height:76px;
  }
}

@media screen and (max-width: 550px) {
  .dimension-logo-example {
      width:332px;
      height: 57px;
  }
}

.app-icon-example-container{
    margin-right:20px;
}

.dimension-app-icon-example {
    width:83px;
    height: 83px;
    overflow: hidden;
    border: 3px solid white;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top:10px;
    margin-bottom:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dimension-app-icon-example-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height: 100%;
  object-fit: cover;
}

.image-icon-plus {
  color: var(--primary-color);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-input-image {
    padding: 20px 0;
}

.button-image-container{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.form-input-image .insible-input {
    display:none;
}

.form-input-image .button-image {
    margin: 20px 0;
    display: grid;
    justify-content: center;
    align-items: center;
    width:150px;
    line-height:50px;
    border-radius: 50px;
    border: 1.5px solid var(--font-secondary-color);
    color: var(--font-secondary-color);
    cursor: pointer;
    font-size: 1em;
    background-color: transparent;
}

.button-image:hover{
    border: 1.5px solid var(--primary-color);
    color: var(--primary-color);
    transition: 0.6s;
}

.form-input-image img {
    width:320px;
    display:none;
    margin-top:10px;
    border-radius: 10px;
    border: 3px solid var(--white);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.group-forms {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5vw;
  gap: 5vw;
}

.form {
  width: 350px;
  display: block;
  background: var(--white);
  padding: 0 50px 50px 50px;
  border-radius: 20px;
  box-shadow: var(--shadow);
}

.icon-container {
  display: flex;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 25px;
  justify-content: flex-end;
}

.form-input {
  background-color: var(--white);
  padding: 5px 10px;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  display: flex;
  outline: none;
  align-items: center;
}

.form-input-textarea{
  background-color: var(--white);
  padding: 5px 10px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  display: flex;
  outline: none;
  align-items: center;
  resize: none;
}

.form-select{
  padding: 5px 10px;
  box-sizing: border-box;
  margin: 20px 0;
  display: flex;
}

.form-select .Dropdown-control{
  border-radius: 5px;
  border: solid 1.5px #E8E8E8;
}

.dropdown-large .Dropdown-control{
  min-width: 150px;
}

.date-picker{
  max-width: 100px;
  padding: 5px 10px;
  margin: 22px 5px;
  border: solid 1px var(--silver);
  font-size: 1em;
  outline: none;
}

.form-select-without-margin-horizontal{
  padding: 5px 0;
  box-sizing: border-box;
  margin: 20px 0;
  display: block;
}

.readonly {
  background-color: #E8E8E8;
}

.copy-input {
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
}

.invisible-input {
  width: 100%;
}

.input-shorter{
  width: 80%;
}

.form-button {
  background-color: var(--primary-color);
  color: var(--white);
  justify-content: center;
  border: none;
  padding: 5px 10px;
  height: 45px;
  border-radius: 50px;
  width: 100%;
  margin: 20px 0;
}

.round-button {
  width: 45px;
  margin: 20px 0;
  transition: 0.5s;
}


.round-button:hover {
  transform: rotate(360deg);
  transition: 0.5s;
}

.two-col {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}


.two-col-kg {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.bi-info-circle-fill {
  color: var(--primary-color);
}

.bi-bi-receipt {
  color: var(--primary-color);
}

.bi-toggle-off {
  color: var(--primary-color);
  font-size: 26px;
}

.bi-toggle-on {
  color: var(--primary-color);
  font-size: 26px;
}

.bi-eye {
  color: var(--primary-color);
  font-size: 20px;
}

.bi-eye-slash {
  color: var(--primary-color);
  font-size: 20px;
}

.bi-arrow-clockwise {
  color: #FFFFFF;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .group-forms {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .form {
    width: 100%;
  }
}

.remove-bg {
  background: transparent !important;
  border: none;
  border-bottom: solid 1px var(--font-primary-color);
  border-radius: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--font-primary-color) !important;
}

.label-tooltip {
  color: var(--primary-color);
  font-size: 12px;
}

.background-color-preview {
  background-color: var(--primary-color);
  font-size: 11px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  float: right;
  margin-right: 10px;
}

.block-picker{
  margin-bottom: 20px;
}

.input-with-color-preview{
  background-color: var(--white);
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  padding: 5px 0 5px 10px;
  display: flex;
  outline: none;
  align-items: center;
}

/*Pop-up styles*/
.blurred {
  filter: blur(4px);
  pointer-events: none;
  transition: filter 0.3s ease;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.custom-modal {
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-backdrop.show {
  z-index: 1040;
}

.table-container {
  position: relative;
  z-index: 1;
}

.form-knox {
  width: 450px;
  height: 80px;
  grid-gap: 40px;
  gap: 40px;
  background: var(--white);
  padding: 0 50px 50px 50px;
  border-radius: 2px;
}


/*Pop-up loading*/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  color: white;
  font-size: 1.2em;
}




.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  justify-content: center;
  align-items: center;

}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-content button{
  margin: 0;
}

.modal-imei-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-imei-content {
  color: #2A2B35;
  line-height: 1.3;
  background-color: #fff;
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.modal-imei-content-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0; 
  z-index: 10;
  background-color: #fff;
}

.modal-imei-content-header-close {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-imei-content-header-logo {
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.modal-imei-content-header-help-logo {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.modal-imei-content-title {
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-scroll {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  max-height: 90vh;
  flex-grow: 1;
}

.modal-imei-content-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.modal-imei-content-item-title {
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
}

.modal-imei-content-item-icon {
  width: 36px;
  display: flex;
}

.modal-imei-content-screen {
  padding-top: 20px;
  height: 200px;
  display: flex;
  margin: 0 auto;
}

.modal-imei-content-help-title {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-subtitle {
  font-size: 16px;
  padding-top: 2px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-explanation {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-explanation-items {
  background-color: #F5F5F5;
}

.modal-imei-content-help-item {
  align-items: center;
  padding: 16px;
}

.modal-imei-content-help-item-title {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.modal-imei-content-help-item-description {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

.modal-imei-content-help-item-icon {
  height: 16px;
}



