.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  justify-content: center;
  align-items: center;

}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-content button{
  margin: 0;
}

.modal-imei-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-imei-content {
  color: #2A2B35;
  line-height: 1.3;
  background-color: #fff;
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.modal-imei-content-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0; 
  z-index: 10;
  background-color: #fff;
}

.modal-imei-content-header-close {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-imei-content-header-logo {
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.modal-imei-content-header-help-logo {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.modal-imei-content-title {
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-scroll {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  max-height: 90vh;
  flex-grow: 1;
}

.modal-imei-content-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.modal-imei-content-item-title {
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
}

.modal-imei-content-item-icon {
  width: 36px;
  display: flex;
}

.modal-imei-content-screen {
  padding-top: 20px;
  height: 200px;
  display: flex;
  margin: 0 auto;
}

.modal-imei-content-help-title {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-subtitle {
  font-size: 16px;
  padding-top: 2px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-explanation {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.modal-imei-content-help-explanation-items {
  background-color: #F5F5F5;
}

.modal-imei-content-help-item {
  align-items: center;
  padding: 16px;
}

.modal-imei-content-help-item-title {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.modal-imei-content-help-item-description {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

.modal-imei-content-help-item-icon {
  height: 16px;
}