body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F8F8F8;
  overflow-x: hidden;
}

body, a {
  text-decoration: none !important;
  font-family: var(--font);
  color: var(--font-primary-color);
  font-size: 1em;
}

body, input {
  font-family: var(--font);
}

body, textarea {
  font-family: var(--font);
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-background);
}

body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.content {
  padding: 50px 0;
}

.App,
.content {
  overflow-x: hidden !important;
}

.demo-container {
  margin: 0 5%;
  width: 100%;
}

.page {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  transition: margin-left 1s;
}

.page-with-navbar {
  margin-left: 270px;
}

.portal-custom-select {
  gap: 20px;
  display: flex;
}

.portal-custom-select select {
  max-width: 150px;
}

.label-container {
  border-radius: 5px;
  display: flex;
  margin: 0;
  align-items: center;
  width: auto;
}

.label-container p {
  padding: 0;
  margin: 0;
}

.custom-select {
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: var(--shadow);
  -webkit-appearance: none;
  width: 60px;
  text-align: center;
  cursor: pointer;
}

.page-title {
  margin: 60px 0;
  color: #606060;
  font-size: 1.3rem;
}

.input-text-b {
  height: 50px;
  box-shadow: var(--shadow);
  -webkit-appearance: none;
  background-color: #FFF;
  padding: 0 20px;
  border-radius: 10px;
  white-space: nowrap;
}

.search-input {
  display: flex;
  align-items: center;
}

@media (max-width: 850px) {
  .search-input {
    display: grid;
    gap: 30px;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 30px;
  background-image: url("../images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  top: 0;
}

.glass-effect{
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

@media (max-width: 850px) {
  .search-input {
    display: grid;
    gap: 30px;
  }
}

.background-login {
  background-color: #FFF !important;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
}

.background-image-profile {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

.background-card {
  border-radius: 10px;
  height: auto;
  padding: 20px;
  display: grid;
  gap: 20px;
}

.text-center {
  text-align: center;
}

.background-card-password {
  border-radius: 10px;
  padding: 20px;
  display: grid;
  gap: 20px;
}

.silver {
  color: #606060;
}

.bi-accent {
  color: #171717;
  font-size: 1.1rem;
}

.filter-container {
  max-width: 70vw;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.invisible-input {
  border: none;
  height: 94%;
  outline: none;
  background: transparent;
}

.center-all {
  display: grid;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}

@media (max-width: 750px) {
  .label-same-size {
    width: 200px;
  }
}

@media (max-width: 540px) {
  .portal-custom-select {
    display: grid;
  }
}

button {
  font-size: 1rem;
  cursor: pointer;
}

.bottom-container {
  padding: 100px 0;
}

.custom-form {
  max-width: 400px;
  background: #FFF;
  padding: 0 50px;
  display: grid;
  gap: 10px;
  color: #606060;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  -webkit-appearance: none;
}

@media only screen and (max-width: 650px) {
  .custom-form {
    max-width: 80vw;
  }

  .text-and-button {
    flex-wrap: wrap;
    gap: 20px;
  }

  .invisible-input, .input-text {
    max-width: 100% !important;
  }
}

.accent {
  color: #171717;
}

.center-button {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.input-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.text-and-button {
  display: flex;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.loader {
  border: 3px solid #c0c0c0;
  border-top: 3px solid #FFF;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}

.pink-loader {
  border: 3px solid #171717;
  border-top: 3px solid #282828;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.unavailable-container {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 85vh;
  font-size: 2rem;
}

.unavailable {
  display: grid;
  gap: 50px;
  text-align: center;
}

.unavailable .bi {
  font-size: 10rem;
  color: #171717;
}

h1{
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 2.5rem;
}

.language-login{
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 30px;
  z-index: 2;
}

.black *{
  color: var(--font-primary-color) !important;
}

.qr-code-container, .qr-code-image-container{
  display: grid;
  gap: 30px !important;
}

.qr-code-image{
  max-width: 300px;
  width: 100%;
  box-shadow: var(--shadow);
  border-radius: 20px;
}
