.small-info-container{
    display: flex;
    width: 381px;
    height: 143px;
    background: #FFFFFF;
    box-shadow: var(--shadow);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: right;
    margin: 30px 0;
}

.small-info-container .silver{
    padding: 0;
    margin: 0;
}

.strong-pink-label{
    font-weight: 600;
    color: var(--primary-color);
    font-size: 50px;
}

.pink-phone{
    height: 70px;
    width: 70px;
    font-size: 4em;
    color: var(--primary-color);
    align-items: center;
}
