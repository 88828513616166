.group-forms {
  display: flex;
  flex-wrap: wrap;
  gap: 5vw;
}

.form {
  width: 350px;
  display: block;
  background: var(--white);
  padding: 0 50px 50px 50px;
  border-radius: 20px;
  box-shadow: var(--shadow);
}

.icon-container {
  display: flex;
  gap: 80px;
  margin-top: 25px;
  justify-content: flex-end;
}

.form-input {
  background-color: var(--white);
  padding: 5px 10px;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  display: flex;
  outline: none;
  align-items: center;
}

.form-input-textarea{
  background-color: var(--white);
  padding: 5px 10px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  display: flex;
  outline: none;
  align-items: center;
  resize: none;
}

.form-select{
  padding: 5px 10px;
  box-sizing: border-box;
  margin: 20px 0;
  display: flex;
}

.form-select .Dropdown-control{
  border-radius: 5px;
  border: solid 1.5px #E8E8E8;
}

.dropdown-large .Dropdown-control{
  min-width: 150px;
}

.date-picker{
  max-width: 100px;
  padding: 5px 10px;
  margin: 22px 5px;
  border: solid 1px var(--silver);
  font-size: 1em;
  outline: none;
}

.form-select-without-margin-horizontal{
  padding: 5px 0;
  box-sizing: border-box;
  margin: 20px 0;
  display: block;
}

.readonly {
  background-color: #E8E8E8;
}

.copy-input {
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
}

.invisible-input {
  width: 100%;
}

.input-shorter{
  width: 80%;
}

.form-button {
  background-color: var(--primary-color);
  color: var(--white);
  justify-content: center;
  border: none;
  padding: 5px 10px;
  height: 45px;
  border-radius: 50px;
  width: 100%;
  margin: 20px 0;
}

.round-button {
  width: 45px;
  margin: 20px 0;
  transition: 0.5s;
}


.round-button:hover {
  transform: rotate(360deg);
  transition: 0.5s;
}

.two-col {
  display: flex;
  gap: 6px;
  align-items: center;
}


.two-col-kg {
  display: flex;
  gap: 10px;
  align-items: center;
}

.bi-info-circle-fill {
  color: var(--primary-color);
}

.bi-bi-receipt {
  color: var(--primary-color);
}

.bi-toggle-off {
  color: var(--primary-color);
  font-size: 26px;
}

.bi-toggle-on {
  color: var(--primary-color);
  font-size: 26px;
}

.bi-eye {
  color: var(--primary-color);
  font-size: 20px;
}

.bi-eye-slash {
  color: var(--primary-color);
  font-size: 20px;
}

.bi-arrow-clockwise {
  color: #FFFFFF;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .group-forms {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .form {
    width: 100%;
  }
}

.remove-bg {
  background: transparent !important;
  border: none;
  border-bottom: solid 1px var(--font-primary-color);
  border-radius: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--font-primary-color) !important;
}

.label-tooltip {
  color: var(--primary-color);
  font-size: 12px;
}

.background-color-preview {
  background-color: var(--primary-color);
  font-size: 11px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  float: right;
  margin-right: 10px;
}

.block-picker{
  margin-bottom: 20px;
}

.input-with-color-preview{
  background-color: var(--white);
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  border: solid 1.5px #E8E8E8;
  padding: 5px 0 5px 10px;
  display: flex;
  outline: none;
  align-items: center;
}

/*Pop-up styles*/
.blurred {
  filter: blur(4px);
  pointer-events: none;
  transition: filter 0.3s ease;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.custom-modal {
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-backdrop.show {
  z-index: 1040;
}

.table-container {
  position: relative;
  z-index: 1;
}

.form-knox {
  width: 450px;
  height: 80px;
  gap: 40px;
  background: var(--white);
  padding: 0 50px 50px 50px;
  border-radius: 2px;
}


/*Pop-up loading*/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  color: white;
  font-size: 1.2em;
}



