.notification-success:before {
  content: "" !important;
}

.notification-info:before {
  content: "" !important;
}

.notification-warning:before {
  content: "" !important;
}

.notification-error:before {
  content: "" !important;
}

.notification {
  padding: 15px 0 15px 15px !important;
}
