.device-mockup{
  display: flex;
}

.device-screen{
  background-color: #ffffff;
  width: 280px;
  height: 600px;
  border-radius: 20px;
  border: solid 6px #121515;
  border-bottom: solid 10px #121515;
  display: block;
}

.silver-border{
  border-radius: 20px;
  background-color: #121515;
  border: solid 6px #3A4245;
}

.button-volume{
  background-color: #121515;
  width: 4px;
  height: 70px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.button-power{
  background-color: #121515;
  width: 4px;
  height: 37px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.device-buttons{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 110px;
}

.device-camera{
  width: 16px;
  height: 16px;
  background-color: #262C2D;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0;
}

.camera-glass{
  width: 8px;
  height: 8px;
  background-color: #121515;
  border-radius: 100%;
}

.camera-brilliance{
  height: 2px;
  width: 2px;
  background-color: #656A6B;
  border-radius: 20px;
  margin: 2px auto 0 4px;
}

.device-content{
  height: calc(100% - 20px);
  border-radius: 40px;
}
