.dashboard{
  width: 100%;
  max-width: 75vw;
  margin: 0 5%;
}

.charts{
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.chart{
  max-width: 100%;
  box-sizing: border-box;
}

#reactgooglegraph-1 div, #reactgooglegraph-2 div{
  background: #FFF !important;
}

@media screen and (max-width: 865px) {
  .dashboard{
    max-width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  .dashboard{
    max-width: 90vw;
  }
}

.group-components{
  width: 100%;
  background: #FFF;
  box-shadow: rgba(110, 110, 110, 0.2) 0px 8px 24px;
  border-radius: 10px !important;
  padding: 5px 20px 20px;
  box-sizing: border-box;
}

.Dropdown-menu{
  max-width: 200px;
}

.alert{
  background: var(--silver);
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px var(--font-tertiary-color);
}
