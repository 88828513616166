.icon-preview-container{
  width: 300px;
  height: 170px;
  border-radius: 30px;
  padding: 20px 30px;
  box-sizing: border-box;
  background-image: url("../img/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-items: flex-end;
  gap: 20px;
}

.icon-pattern{
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-pattern-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height: 100%;
  object-fit: cover;
}

.instagram-background{
  background: radial-gradient(circle at 33% 100%,
  #f5804e 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  );
  display: flex;
  justify-content: center;
  padding-top: 5px;
  box-sizing: border-box;
}

.bi-instagram, .bi-twitch{
  font-size: 30px;
  color: #fff;
}


.twitch-background{
  background-color: #9046FF;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  box-sizing: border-box;
}

.icons-preview{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icons-preview *{
  transition: all 1s;
}

.icon-placeholder{
  background-color: #FFFFFF;
  position: relative;
}

.navigation-controls{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navigation-controls .bi{
  font-size: 20px;
  color: #fff;
}

.icon-placeholder:hover{
  height: 60px;
  width: 60px;
  cursor: pointer;
  transition: all 1s;
}
