@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --white: #ffffff;
  --primary-background: #212121;
  --secondary-background: #f5f5f5;
  --silver: #e0e0e0;
  --silver-hover: #eee;
  --primary-color: #171717;
  --font: 'Poppins', sans-serif;
  --font-primary-color: #212121;
  --font-secondary-color: #757575;
  --font-tertiary-color: #bdbdbd;
  --shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
}
