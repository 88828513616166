.multipart{
  display: flex;
  gap: 5vw;
  width: 100%;
  min-height: 600px;
  border-radius: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: start;
}

.form-index {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-index-labels p {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 30px 0;
  text-align: end;
}

.form-index-buttons {
  display: grid;
  grid-template-columns: auto; /* Uma coluna para os botões */
}

.form-index-buttons button {
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: solid 1px var(--font-secondary-color);
  color:  var(--font-secondary-color);
  transition: all 0.2s;
}


.active-btn{
  background-color: black !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s;
}

.line{
  height: 30px;
  background-color: var(--font-secondary-color);
  width: 1px;
  margin: 0 auto;
}

.outline-btn{
  background-color: transparent;
  color: var(--primary-background);
  border-radius: 30px;
  width: 200px;
  height: 50px;
  border: solid 1px var(--primary-background);
}


.form-step{
  width: 100%;
  height: 100%;
  display: grid;
  align-items: stretch;
  gap: 30px;
}

.step-content{
  width: 100%;
}

.step-controls{
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 10px;
  flex-wrap: wrap;
}

.app-visual-input{
  display: flex;
  gap: 30px;
  align-items: start;
  flex-wrap: wrap;
}
