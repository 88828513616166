.display-flex{
    display: flex;
    align-items: center;
    gap: 20px;
}

.dimension-example{
    width:320px;
    height: 96px;
    border: 1.5px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
}

.dimension-logo-example{
    max-width:100%;
    height: auto;
    border: 3px solid white;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top:10px;
    display: flex;
    justify-content: center;
    object-fit: contain;
}

@media screen and (min-width: 550px) {
  .dimension-logo-example {
      width:450px;
      height:76px;
  }
}

@media screen and (max-width: 550px) {
  .dimension-logo-example {
      width:332px;
      height: 57px;
  }
}

.app-icon-example-container{
    margin-right:20px;
}

.dimension-app-icon-example {
    width:83px;
    height: 83px;
    overflow: hidden;
    border: 3px solid white;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top:10px;
    margin-bottom:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dimension-app-icon-example-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height: 100%;
  object-fit: cover;
}

.image-icon-plus {
  color: var(--primary-color);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-input-image {
    padding: 20px 0;
}

.button-image-container{
    display: flex;
    gap: 20px;
}

.form-input-image .insible-input {
    display:none;
}

.form-input-image .button-image {
    margin: 20px 0;
    display: grid;
    justify-content: center;
    align-items: center;
    width:150px;
    line-height:50px;
    border-radius: 50px;
    border: 1.5px solid var(--font-secondary-color);
    color: var(--font-secondary-color);
    cursor: pointer;
    font-size: 1em;
    background-color: transparent;
}

.button-image:hover{
    border: 1.5px solid var(--primary-color);
    color: var(--primary-color);
    transition: 0.6s;
}

.form-input-image img {
    width:320px;
    display:none;
    margin-top:10px;
    border-radius: 10px;
    border: 3px solid var(--white);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
