nav{
  position:fixed;
  top:0;
  left:0;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  min-width: 250px;
  width: 250px;
  min-height: 100vh;
  background-color: #FFF;
  padding:2rem 1rem 1rem 1rem;
  transition: transform 1s;
  color: #171717 !important;
  box-sizing: border-box;
  box-shadow: var(--shadow);
  z-index: 9;
}

nav * {
  color: #606060 !important;
  font-size: 1.1rem;
}

nav .bi {
  color: #171717 !important;
}

.navbar{
  transform: translateX(-100%);
}

.nav-btn{
  position: absolute;
  transform: translateX(38px);
  top: 20px;
  right: 0;
  width:40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #171717 !important;
  box-shadow: rgba(149, 157, 165, 0.1) 8.4px 8.4px 14px;
}

.logo{
  display: block;
  background:transparent;
}

.logo img{
  display: block;
  width: 90%;
  height: 90%;
  margin: auto;
}

.partner-name{
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.nav-link{
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 10px 1rem;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.nav-link span{
  margin-left: 10px;
}

.language-option-visible{
  display: grid;
  padding: 30px;
  animation: fade 2s;
  gap: 15px;
  background: #F8F8F8;
  border-radius: 5px;
}

.language-option-visible span{
  cursor: pointer;
}

.language-option{
  display: none;
  margin-left: 20px;
}

.language{
  display: flex;
  gap: 20px;
  align-items: center;
}

.language img{
  width: 20px;
}

@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.nav-top{
  margin-top: 15vh;
  gap: 5vh;
}

.mobile-nav{
  background-color: #FFF;
  width: 100vw;
  height:38px;
  display: none;
  box-shadow: var(--shadow);
  z-index: 10;
  position: fixed;
}

.mobile-nav-btn{
  box-sizing: border-box;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 10px;
}

.mobile-nav-btn .bi {
  font-size: 20px;
  color: #171717;
}

@media only screen and (max-width: 768px){
  nav{
    padding: 10px;
  }
}

@media only screen and (max-width: 450px){
  .mobile-nav{
    display: flex;
    align-items: center;
  }
  nav{
    width: 70%;
    top: 38px;
    min-height: calc(100vh - 38px);
  }

  .nav-btn{
    display: none;
  }

  .blur{
    filter: blur(20px);
  }
}
