.lock-type-container{
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: space-evenly;
  gap: 40px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.lock-type-container *{
  margin: 0 auto;
}

.partial-lock-pop-up{
  box-shadow: var(--shadow);
  background-color: white;
  text-align: center;
  padding: 10px 10px 25px;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  gap: 10px;
  align-content: center;
  align-items: center;
  width: 100%;
  border-radius: 20px;
}

.ads-pop-up{
  box-shadow: var(--shadow);
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  display: grid;
  padding: 10px;
  justify-content: center;
  align-content: space-evenly;
}

.ads-container{
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  align-content: center;
  text-align: center;
}

.ads-container img{
  margin: 0 auto;
}

.ads-placeholder{
  height: 230px;
  background-color: var(--silver);
  border-radius: 20px;
}

.timer-example{
  height: 40px;
  width: 40px;
  border: solid 2px var(--silver);
  display: flex;
  justify-content: center;
  color: var(--font-secondary-color);
  border-radius: 30px;
  font-size: 30px;
}

.align-to-right{
  display: flex;
  justify-content: right;
}


.launcher{
  height: calc(100% - 10px);
  width: 100%;
  background-color: silver;
  border-radius: 10px;
  margin-top: 10px;
  background-image: url("../img/background.jpg");
  background-size: cover;
  background-position: center;
  color: #FFFFFF;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  align-items: flex-start;
}
