body {
  padding: 0;
  background-color: #FFFFFF !important;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-background);
}

body::-webkit-scrollbar-thumb {
  background: var(--font-secondary-color) !important;
}

.app-main {
  padding: 100px 10vw;
  min-height: 200vh;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 20px 5vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}

.app-header h1 {
  font-size: 1.3em;
}

.app-display {
  display: flex;
  gap: 5vw;
  align-items: center;
  text-align: center;
}

.app-name {
  font-size: 1.8em;
  font-weight: normal;
  text-align: left;
}

.app-category {
  margin-top: 20px;
  font-size: 1.3em;
  font-weight: normal;
  color: #616161;
}

.app-icon {
  max-width: 200px;
  max-height: 200px;
}

.app-icon img {
  width: 100%;
  border-radius: 20px;
}

.app-download {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.download-button-mobile-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 915px) {
  .download-button-mobile-container {
    width: 100%;
    justify-content: center;
    margin-top: 40px;
  }

  .qr-code-container, .app-qr-code {
    width: 100%;
  }

  .qr-code-container{
    margin-top: 40px;
  }

  .app-display{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
  }
}

.qr-code-container {
  position: relative;
  display: inline-block;
}

.download-button {
  padding: 10px;
  background-color: #000000;
  border-radius: 90px;
  width: 200px;
  height: 35px;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  display: flex;
  gap: 10px;
  font-size: 1.2em;
}

.download-button .bi{
  -webkit-text-stroke: 0.6px;
}

.download-link {
  display: none;
  position: absolute;
  bottom: 35%;
  left: 10%;
}

.app-qr-code {
  transition: 0.7s;
}

.qr-code-container:hover > .app-qr-code {
  filter: blur(8px);
  opacity: 0.4;
  transition: 0.7s;
}

.qr-code-container:hover > .download-link {
  display: flex;
  transition: 0.3s;
}

.app-silver-box {
  display: grid;
  padding: 20px;
  background-color: #F3F3F3;
  border-radius: 10px;
  gap: 20px;
}

.app-h4 {
  font-weight: 500;
  font-size: 1.3em;
}

.app-information {
  display: flex;
  flex-wrap: wrap;
  padding: calc(10vw + 30px) 0;
}

.card {
  flex: 1;
  min-width: 300px; /* Adjust as needed */
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .card {
    flex: 0 0 100%;
  }

  .app-main {
    padding: 100px 5vw;
  }

  .app-name {
    text-align: center;
  }
}

.app-information div {
  display: grid;
  gap: 30px;
}

.app-instalation-manual {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.chevron {
  min-height: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.app-instalation-manual .app-h4 {
  text-align: center;
  margin-bottom: 60px;
}


.store-tab-menu{
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}


.tab-active{
  background-color: #F3F3F3;
}

.store-tab-menu li{
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.installation-container{
  padding: 80px 0;
}



/* LIGHTBOX */

.wrapper {
  max-width: 1350px;
  display: flex;
  gap: 20px;
  margin-top: 80px;
}

.wrapper-images{
  text-align: center;
  display: grid;
  gap: 10px;
  color: silver;
}

.wrapper-images img{
  text-align: center;
  display: grid;
  max-width: 400px;
  height: 500px;
  gap: 10px;
  border-radius: 5px;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 999;
}

.overlay-arrows_right {
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 999;
}

.dismiss{
  z-index: 999;
  font-size: 2em;
}

/* LIGHTBOX */


.inner-carrousel {
  display: flex !important;
  box-sizing: border-box;
  gap: 20px;
}

.carrousel {
  cursor: grab;
  overflow-x: hidden;
  box-sizing: border-box;
}

